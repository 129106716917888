.teamPage{
  &.static-page .image-page{
    padding: 0;
  }

  .blend-multiply {
    background-blend-mode: multiply;
  }

  .img-ctn img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  div.title {
    color: #C7A446;
    font-family: 'Radikal';
    font-size: 42px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
  }
  
  div.text {
    color: #FFF;
    font-family: 'Radikal';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 22.4px */
  }

  .content-team-1.image-page {
    height: 815px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .title {
      margin: 0 62px 62px 0;
      width: 208px;
      color: #FFF;
    }
  }

  .content-team-2 {
    padding: 75px 0;
    display: flex;
    flex-direction: column;
    gap: 100px;
    background: rgba(12, 32, 50, 0.98);
    justify-content: center;
    align-items: center;
    width: 100%;

    .content-wrapper {
      display: flex;
      
      &.sec-01 {
        justify-content: flex-end;
        padding-right: 60px;
        gap: 80px;
        .text-holder {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 37px;
          width: 408px;
          text-wrap: nowrap;
        }
        .img-ctn {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          max-width: 818px;
          max-height: 722px;
          .ctn-upper {
            display: flex;
          }
          .ctn-0201 {
            max-width: 55%;
          }
          .ctn-0202 {
            width: 376px;
            max-width: 45%;
            padding: 137px 0 105px 74px;
          }
          .ctn-0203 {
            max-width: 428px;
            width: 53.5%;
            margin:-129px 50px 0 0;
          }
        }
      }

      &.sec-02 {
        justify-content: flex-end;
        gap: 100px;
        .content-left {
          width: 471.5px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-wrap: nowrap;
          .img-ctn {
            max-width: 490px;
            max-height: 302px;
            margin-bottom: 75px;
          }
          .text-holder {
            padding-left: 60.5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 37px;
            .text {
              width: 360px;
            }
          }
        }
        .img-ctn {
          max-width: 796px;
          max-height: 822px;
        }
      }
      
      &.sec-03 {
        justify-content: flex-end;
        gap: 100px;
        padding-right: 62px;
        text-wrap: nowrap;
        .img-ctn {
          max-width: 862px;
          max-height: 544.803px;
        }
        .text-holder {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 37px;
          width: 344px;
        }
      }
      
      &.sec-04 {
        justify-content: flex-start;
        gap: 60px;
        margin-bottom: 80px;
        padding-left: 62px;
        text-wrap: nowrap;
        .img-ctn {
          max-width: 888px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-areas: 
            "team0501    team0502    team0503"
            "team0504    team0505    team0506";
          gap: 20px;
          .ctn-0501 { grid-area: team0501; }
          .ctn-0502 { grid-area: team0502; }
          .ctn-0503 { grid-area: team0503; }
          .ctn-0504 { grid-area: team0504; }
          .ctn-0505 { grid-area: team0505; }
          .ctn-0506 { grid-area: team0506; }
        }
        .text-holder {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 37px;
          width: 356px;
        }
      }
    }
  }
}

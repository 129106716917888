.sessionPage {
  background-color: #0C2032;

  .image-page-wrapper {
    overflow: hidden;
  }

  .image-page {
    position: relative;
    width: 1510px !important;
    height: 850px !important;
    background-color: #0C2032;
    flex-shrink: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url("../../assets/images/post-session-1.png"), lightgray 50% / cover no-repeat;
    margin-top: -0.4px;
    margin-left: -72px;
    margin-right: -72px;
    margin-bottom: -200px;
    ::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0C2032;
      // opacity: 0.7;
      z-index: -1;

    }

    .txtImg {
      opacity: 1;
      width: 1066px;
      color: #FFF;
      text-align: center;
      font-family: Radikal;
      font-size: 66px;
      font-style: normal;
      font-weight: 300;
      line-height: 60%;
      /* 39.6px */
      margin-bottom: 200px;

    }
  }

  .post-engagement.content-page {
    padding-top: 100px !important;
  }

  .content-page {
    padding-top: 180px !important;
    padding-left: 150px !important;
    padding-bottom: 50px !important;

    .title-bottom {
      color: #fff;

      /* Medium header */
      font-family: Radikal;
      font-size: 48px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      width: 360px;
    }

    .content-bottom {
      color: #fff;
      width: 360px;

      /* Body text */
      font-family: Radikal;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
      /* 22.4px */
    }
  }

  .close-view {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.4);

    span {
      color: #c7a446;
      text-align: center;
      font-family: "Radikal";
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
    }
  }

  .post-engagement-content {
    width: 100%;
    height: 818px;
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;

    .post-engagement-content-units {
      display: flex;
      flex-direction: column;
      gap: 37px;
      width: 360px;
      color: #fff;

      .units-title {

        /* Medium header */
        font-family: Radikal;
        font-size: 48px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      .units-description {
        color: #FFF;

        /* Body text */
        font-family: Radikal;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
        /* 22.4px */
      }

      margin-top: 139px;

      &.left {
        margin-left: 150px;
      }

      &.right {
        margin-right: 150px;
      }
    }

    .post-engagement-content-images {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-top: 100px;

      &.left {
        align-items: start;
      }

      &.right {
        align-items: end;
      }

      .unit-images {
        &-1 {
          width: 550px;
          height: 458px;
          flex-shrink: 0;

          &.left {
            margin-left: 50px;
          }

          &.right {
            margin-right: 50px;
          }
        }

        &-2 {
          width: 380px;
          height: 198px;
          margin-top: -63px;
          flex-shrink: 0;

          &.left {
            margin-left: 473px;
          }

          &.right {
            margin-right: 473px;
          }
        }

        &-3 {
          width: 344px;
          height: 190px;
          margin-top: -115px;
          flex-shrink: 0;

          &.left {
            margin-left: 108px;
          }

          &.right {
            margin-right: 108px;
          }
        }
      }
    }
  }
}

.modal-backdrop.fade {
  opacity: 0 !important;
}

.wrap-user-type-modal {
  .modal-content {
    border-radius: 0;
    color: #000000;
    background-color: #ffffff;
  }

  .wrap-modal-body {
    padding: 2rem;
    overflow-y: hidden;
    .icon-close{
      position: absolute;
      right: 22px;
    }
    .btn {
      border: #fff;
      border-width: 1px;
    }
    .modal-form__title {
      font-size: 24px;
      color: #C7A446;
      font-family: "RadikalMedium";

      &.underline-sm {
        &::before {
          width: 0;
          height: 0;
        }
      }
    }
  }

  .btn-outline {
    border: none;
    border-radius: 0;
    padding: 16px 54px;
    background-color: #C7A446;
    font-family: "RadikalMedium";
    color: #ffffff;
    font-size: 16px;
  }
  .btn-outline:first-child{
    margin-bottom: 20px;
  }
}

.wrap-user-languague-modal {
  .modal-content {
    border-radius: 0px;
    background-color: #fff;
    color: #000;
  }
  width: 540px;
  height: 100px;

  .wrap-modal-body {
    padding: 2rem;
    overflow-y: hidden;

    .btn {
      border: #000;
      border-width: 1px;
    }
    .modal-form__title {
      font-size: 20px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #fff;
          bottom: -9px;
          height: 1px;
        }
      }
    }
  }

  .btn-languague {
    background-color: #fff;
  }

  .btn-outline {
    border: 1px solid #fff;
    border-radius: 0px;
    width: 130px;
    background-color: #fff;
  }

  .languague-buttons {
    justify-content: space-between;
    display: flex;
    button{
      width: 130px;
      height: 36px;
      border: 1px solid #000000;
      flex: none;
      order: 2;
      flex-grow: 1;
      background: white;
      margin-right: 35px;
      span{
        font-weight: 450;
        font-size: 18px;
        line-height: 200%;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        order: 0;
        flex-grow: 0;
      }
    }
  }

  .modal-dialog{
    max-width: 540px!important;
  }
}

.wrap-list-user-modal {
  .modal-dialog {
    max-width: 600px;
    .modal-content {
      border-radius: 0;
      color: #000000;
      background-color: #ffffff;
    }
  }
  .wrap-modal-body {
    padding: 2rem;
    overflow-y: hidden;
    .modal-form__title {
      font-size: 24px;
      color: #C7A446;
      font-family: "RadikalMedium";

      &.underline-sm {
        &::before {
          width: 0;
          height: 0;
        }
      }
    }
    .modal-form__sub-title {
      font-size: 16px;
      font-family: "RadikalRegular";
      color: #0C2032;
      button {
        background: #fff;
        color: #C7A446;
        font-weight: normal;
        border: none;
      }
    }
    .wrapper-list-user {
      .first-last-name{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .form-info{
        display: flex;
        flex-direction: column;
        width: 48%;
        label{
          font-family: 'RadikalRegular';
          font-size: 14px;
          color: #2D2927;
        }
        input{
          border: 1px solid #C7A446;
          font-family: 'RadikalRegular';
          font-size: 14px;
          text-align: left;
          height: 50px;
          padding-left: 15px;
          margin-top: 5px;
        }
        input::placeholder{
          color: #000000;
        }
        input:focus{
          outline-color: #0C2032;
        }
      }
    }
    .wrap-button-list-user{
      button {
        color: #000;
        background-color: transparent;
        border: none;
        font-size: 14px;
        text-transform: uppercase;
        width: auto;
      }
      button:first-child{
        background-color: #C7A446;
        height: 50px;
        width: 100px;
        color: #fff;
        &:hover{
          background-color: #C9C9C9;
        }
      }
    }
  }

  .wrap-user-list {
    padding: 8px 16px 8px 6px;
    overflow-y: scroll;
    height: 200px;
    border-style: groove;
    color: #fff;

    p {
      padding-left: 10px;
      padding-top: 12px;
      margin-bottom: 0px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;

      &:active {
        background-color: #7A7A7A;
        color: #eeeeee;
      }

      &:focus {
        background-color: #000;
        color: #eeeeee;
      }
    }

    .cursor-pointer {
      background-color: #000;
      color: #fff;
    }

    .cursor-pointer:active {
      background-color: #7A7A7A;
      color: #eeeeee;
    }

    .cursor-pointer:focus {
      background-color: #7A7A7A;
      color: #eeeeee;
    }
  }

  .wrap-button {
    margin-top: 16px;
    button {
      background: #000;
      color: #fff;
      font-weight: normal;
      border: 1px solid #000;
      font-size: 14px;
      text-transform: uppercase;
      text-decoration-line: underline;
      padding: 0;
      width: auto;
    }
  }

  ::-webkit-scrollbar {
    width: 30px;
  }

  ::-webkit-scrollbar-track {
    border-left: 12px solid #eeeeee;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-left: 12px solid #D5D5D5;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
}

.wrap-not-found{
  background-color: rgba(0,0,0,0.8);
  .modal-dialog {
    .modal-content {
      background-color: #FEF8EF;
      color: #000000;
      border-radius: 0px;
      padding: 20px 30px;
    }
  }
  .wrap-modal-body {
    .modal-not-found{
      display: flex;
      flex-direction: column;
      .title{
        font-size: 24px;
        color: #C7A446;
        font-family: "RadikalMedium";
      }
      .sub{
        font-family: "RadikalRegular";
        font-size: 16px;
        line-height: 200%;
        color: #0C2032;
        margin-top: 35px;
      }
      button {
        background-color: transparent;
        color: #0C2032;
        border: none;
        font-family: "RadikalRegular";
        font-size: 14px;
        width: 92px;
        text-transform: uppercase;
        text-align: left;
        margin-top: 30px;
        padding: 0;
      }
    }
  }
}

.wrap-edit-user-profile-modal{
  background: rgba(0,0,0,0.5);
}

.wrap-create-user-profile-modal {
  .modal-dialog {
    max-width: 600px;

    .modal-content {
      border-radius: 0;
    }
  }

  .wrap-modal-body {
    padding: 2rem;
    overflow-y: hidden;
    background-color: #000;
    color: #fff;
    .btn-close-edit-modal{
      position: absolute;
      right: 20px;
      cursor: pointer;
      svg{
        width: 20px;
      }
    }
    .wrap-button {
      margin-top: 50px;
      justify-content: flex-end !important;

      button {
        background: #000;
        color: #fff;
        padding: 16px;
        font-weight: normal;
        border: 1px solid #000;
        font-size: 14px;
        margin-left: 16px;
        margin-right: -16px;
        width: 92px;
        text-transform: uppercase;
        text-decoration-line: underline;
      }
    }
    .modal-form__title {
      font-size: 24px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }
    .modal-form__sub-title {
      font-size: 18px;
    }
    .form-group {
      border: 1px dashed #414345;
      width: 100%;
      height: 54px;
      margin-top: 16px;
      span {
        padding-top: 16px;
      }
    }
    .form-control {
      border-radius: 0;
      border: 0;
      height: 40px;
      line-height: 2;
      background: #000;
      padding-left: 12px;
      font-size: 14px;
      padding-top: 1rem;
      color: #fff;
      text-align: center;
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        text-transform: uppercase;
      }
    }
    .label {
      color: #414345;
      border-bottom: 1px solid #414345;
      font-size: 14px;
    }
    .type-value {
      font-size: 14px;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .modal-edit-profile{
    background-color: #ffffff;
    color: #000000;
    .wrap-button {
      button {
        background: #fff;
        color: #000;
      }
    }
    .form-control {
      background: #fff;
      color: #000;
      text-align: left;
    }
    .wrap-button-bottom{
      .text-uppercase{
        color: #000;
        border-bottom: 1px solid #000;
      }
    }
  }

  .wrap-button-bottom{
    .text-uppercase{
      color: #fff;
      border-bottom: 1px solid #fff;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
    }
    div:last-child{
      margin-left: 40px;
    }
  }
}

.wrap-end-guide-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 90;
  background-color: #000;
  opacity: 1;

  .modal-end-guide {
    background-color: #fff !important;
    position: absolute;
    top: 120px;
    left: 60px;
    width: calc(100% - 120px);
    height: calc(100% - 240px);

    .modal-end-guide-body {
      height: 80%;
      overflow-x: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 10px;
        margin-right: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #eeeeee;
      }

      &::-webkit-scrollbar-thumb {
        background: #d4d4d4;
        min-height: 40px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      border: unset;
      background-color: unset;
      font-size: 24px;
      padding: 16px;
      line-height: 15px;
      font-weight: 300;

      &:hover {
        background-color: #fff;
      }
    }
  }
}

.wrap-video-intro {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: black;
  display: flex;
  align-items: center;

  .btn-skip-video {
    position: absolute;
    bottom: 80px;
    right: 35px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 50px;
    width: 112px;
    display: flex;
    font-size: 16px;
    border: 2px solid #FFFFFF;
    color: #FFFFFF;
    border-radius: 39px;
    background: rgba(255, 255, 255, 0.15);
    font-family: "RadikalRegular";
  }
  .btn-close-modal{
    position: absolute;
    top: 50px;
    right: 50px;
    cursor: pointer;
  }
}

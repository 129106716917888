.lifePage{
  &.static-page .image-page{
    padding: 0;
  }

  .blend-multiply {
    background-blend-mode: multiply;
  }
  
  div.text {
    color: #FFF;
    font-family: 'Radikal';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 22.4px */
    span.bold {
      font-family: 'Radikal';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
    }
  }

  .content-life-1.image-page {
    height: 768.38px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    .text {
      margin: 0 0 62px 62px;
      width: 310px;
    }
  }

  .content-life-2 {
    padding: 75px 0;
    display: flex;
    flex-direction: column;
    gap: 100px;
    background: rgba(12, 32, 50, 0.98);
    color: #FFF;
    justify-content: center;
    align-items: center;
    width: 100%;

    .content-wrapper {
      display: flex;
      text-wrap: nowrap;
      
      &.upper {
        justify-content: flex-start;
        padding-left: 62px;
        gap: 111px;
        .text {
          display: flex;
          align-items: center;
          width: 350px;
        }
        .img-ctn {
          max-width: 843px;
          max-height: 632.25px;
        }
      }

      &.lower {
        justify-content: flex-end;
        padding-right: 62px;
        gap: 100px;
        .text {
          display: flex;
          align-items: center;
          width: 400px;
        }
        .img-ctn {
          max-width: 804px;
          max-height: 527px;
        }
      }
    }
  }

  .content-life-3.image-page {
    height: 1042px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .text-holder {
      display: flex;
      flex-direction: column;
      gap: 37px;
      margin: 62.37px 0 0 62px;
      width: 388px;
      .title {
        font-size: 42px;
        line-height: normal;
      }
    }
  }

  .content-life-4 {
    padding: 75px 0;
    display: flex;
    flex-direction: column;
    background: rgba(12, 32, 50, 0.95);
    color: #FFF;
    justify-content: center;
    align-items: center;
    width: 100%;

    .content-wrapper {
      display: flex;
      justify-content: flex-start;
      gap: 100px;
      text-wrap: nowrap;
      padding-left: 62px;
      .text {
        display: flex;
        align-items: center;
        width: 316px;
      }
      .img-ctn {
        max-width: 888px;
        max-height: 499.5px;
      }
    }
  }

  .content-life-5.image-page {
    height: 768.218px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    .text {
      width: 400px;
      margin: 0 0 62px 62px;
    }
  }

  .content-life-6 {
    padding: 75px 0 75.5px 0;
    display: flex;
    flex-direction: column;
    background: rgba(12, 32, 50, 0.98);
    color: #FFF;
    justify-content: center;
    align-items: center;
    width: 100%;

    .content-wrapper {
      display: flex;
      justify-content: flex-start;
      gap: 100px;
      text-wrap: nowrap;
      padding-left: 62px;
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 361px;
      }
      .img-ctn {
        max-width: 843px;
        max-height: 921.498px;
      }
    }
  }
}

.renders-content {
  overflow-y: auto;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 260px;

  .renders-content-row {
    display: flex;
    gap: 15px;

    .renders-content-col {
      flex-basis: 33.33%;
      width: 33.33%;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }


  .card-renders {
    background-color: #FFFFFF;
    width: 100%;
    height: auto;
    position: relative;
    cursor: pointer;

    .title {
      position: absolute;
      bottom: 15px;
      left: 15px;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 116%;
      letter-spacing: .3px;
      text-transform: uppercase;
      color: #fff;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .infinity {
    background-color: #E3E3E3;
  }
}

.films-content {
  overflow-y: auto;
  height: 100%;
  padding-top: 104px;

  .films-row {
    padding-bottom: 260px;
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }

  .card-films {
    height: 286px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;

    .sub {
      size: 22px;
      font-weight: 600;
      color: #fff;
    }

    .title {
      size: 31px;
      font-weight: 700;
      color: #fff;
    }

    .divider {
      height: 1.5px;
      width: 60px;
      background: #fff;
      margin-top: 13px;
      margin-bottom: 39px;
    }

    img {
      width: 75px;
      cursor: pointer;
    }
  }

  .infinity {
    background-color: #E3E3E3;
  }

  .wrap-video-film {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
    background-color: black;
    display: flex;
    align-items: center;

    .btn-close-video {
      position: fixed;
      top: 120px;
      right: 45px;
      cursor: pointer;
    }
  }
}

.floor-plans-content {
  width: 100%;
  height: 100%;
  //overflow-y: scroll;
  display: flex;
  flex-direction: column;
  //position: relative;

  .content-floor {
    height: 100%;
    overflow-y: auto;
    display: flex;
    padding: 166px 150px 0 150px;

    .aw-header-content {
      margin-top: 30px;
      width: 360px;

      .aw-title {
        color: #FFF;
        font-size: 48px;
        font-family: Radikal;
        font-weight: 300;
      }

      .aw-sub-title {
        color: #FFF;
        font-size: 16px;
        font-family: Radikal;
        font-weight: 300;
        line-height: 140%;
      }
    }

    .aw-body-content {
      flex: 1;

      .aw-row {
        display: flex;
        flex-direction: row;
        gap: 15px;
        flex-wrap: wrap;

        .aw-body-item {
          display: flex;
          padding: 3px 6px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 1px solid #FFF;

          width: 125px;
          height: 125px;
          cursor: pointer;

          span {
            color: #FFF;
            font-size: 16px;
            font-family: Radikal;
            font-weight: 300;
            line-height: 140%;
          }
        }
      }
    }
  }

  .header-content {
    margin-top: 33px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    height: fit-content;

    .title {
      font-size: 24px;
      font-weight: 600;
    }

    .aw-title {
      color: #FFF;
      font-size: 48px;
      font-family: Radikal;
      font-weight: 300;
    }
  }

  .body-content {
    width: 100%;
    padding: 0 200px;
    margin-top: 45px;

    .car-floor {
      justify-content: center;
      align-items: center;
      display: flex;
      cursor: pointer;

      span {
        font-weight: 400;
        font-size: 45px;
      }
    }
  }

  .content-plans {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 100px 70px 100px 150px;

    .aw-header-content {
      margin-top: 30px;
      width: 360px;

      .aw-title {
        color: #FFF;
        font-size: 48px;
        font-family: Radikal;
        font-weight: 300;
      }

      .aw-sub-title {
        color: #FFF;
        font-size: 16px;
        font-family: Radikal;
        font-weight: 300;
        line-height: 140%;
      }
    }

    .aw-image-container {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .aw-image-item {
        position: relative;
        display: inline-block;
        height: 100%;
        //max-width: 100%;
        //max-height: 100%;
      }

      .plans-marker {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      .img-floor {
        width: 100%;
        height: auto;
        max-height: 100%;
        //max-width: 100%;
        object-fit: contain;
      }

      .img-floor-fh {
        height: 100%;
        max-width: 100%;
      }

      .img-floor-fw {
        width: 100%;
        max-height: 100%;
      }

    }

    .content-plans-level {
      margin-top: 20px;
      cursor: pointer;

      .content-plans-level-text {
        margin-left: 10px;
        color: #C7A446;
        font-size: 14px;
        font-family: Radikal;
        font-weight: 300;
      }
    }
  }
}

.infinity {
  //background-color: #E3E3E3;
}

.modal-floor {
  .header-content {
    margin-top: 0;
  }

  .body-content {
    padding: 0 20px;

    .car-floor {
      width: 50px;
      height: 50px;
      border-radius: 25px;

      span {
        font-size: 30px;
      }
    }

    .isSelected {
      background-color: #000000;

      span {
        color: #FFFFFF;
      }
    }
  }
}

.modal-view-plans {
  .btn-close-modal {
    top: 40px;
  }

  .modal-base-content {
    height: 100vh !important;
    min-width: 100vw !important;

    .modal-base-body {
      height: 100% !important;
      background-color: rgb(250, 250, 250);
      overflow-x: hidden;
      padding-left: 40px !important;
      padding-right: 40px !important;

      .view-plans {
        height: 100%;

        .slider-floor-plan {
          .img-plans-content {
            height: calc(100vh - 50px);
            display: flex !important;

            img {
              width: calc(100vw - 200px);
              object-fit: cover;
              margin: auto;
            }
          }
        }

        .slick-slider {
          .slick-arrow {
            width: 25px;
            height: 50px;
          }

          .slick-next {
            transform: rotate(180deg);
            top: 45%;
          }
        }
      }
    }
  }
}

.wrap-fancy-close {
  position: absolute;
  z-index: 1055;
  top: 45px;
  right: 45px;
}

.wrap-btn-fancy {
  position: absolute;
  z-index: 1055;
  bottom: 45px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .wrap-slide-fancy {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 14px;
  }

  .fancy-dot {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #C7A446;
    cursor: pointer;
  }

  .fancy-dot.fancy-dot-o {
    background-color: transparent;
    border: 1.5px solid #C7A446;
  }
}

.pure-fancy-box > .fancybox__toolbar {
  display: none !important;
}

.pure-fancy-box .fancybox__slide {
  padding: 0px 0px 00px 0px !important;
}

@import '../../common.scss';

$spacer: 10px;
$white: '#fff';

.sidenav {
  padding: $spacer;

  &__heading {
    padding: $spacer/2 $spacer $spacer * 0.75;
    flex-shrink: 0;
  }

  &__body {
    flex: 1;
    overflow-y: scroll;
    justify-content: space-between;
  }

  &--dark {
    background-color: transparentize(#1e1e1e, 0.9);

    .list-group {
      margin-bottom: 10px;

      &-item {
        color: $white;
      }
    }
  }
  &--light {
    background-color: rgba($color: #fff, $alpha: 1);

    .list-group {
      margin-bottom: 10px;

      &-item {
        color: $white;
      }
    }
  }
}

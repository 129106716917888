.wrap-location {
  position: absolute;
  z-index: 90;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;

  .wrap-list-location {
    padding: 14px;
    height: calc(100vh - 400px);
    background-color: rgba(0,0,0, 0.65) !important;
    h2::before {
      display: none;
    }

    .card-body {
      margin-bottom: 25px;
    }

    .accordion {
      padding-right: 14px;
    }

    .list-group-item {
      opacity: 1;
      letter-spacing: .12em;
      color: #FFFFFF;
      font-family: "RadikalRegular";
      text-transform: none;

      &.heading, &.active {
        font-weight: 500;
      }

      &.list-group-heading {
        font-weight: 500;
      }

      &.list-group-type {
        color: #A3A394;
        text-transform: uppercase;
        cursor: initial;
      }
    }

    .card-title {
      color: #C7A446;
      font-family: 'RadikalMedium';
      font-size: 20px;
      text-transform: none;
    }

    .list-group-heading::before {
      content: '';
      position: absolute;
      left: -1.25rem;
      margin-top: -2px;
      top: 50%;
      width: 25px;
      height: 2px;
      background-color: #000000;

      @media screen and (max-width: 1025px) {
        width: 15px;
      }
    }
  }
}

.wrap-modal {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 70vh;
  overflow: hidden;
  max-width: 800px;
  box-shadow: -4px 5px 4px 0 rgba(#000, .25);

  h4 {
    font-size: 12px;
    letter-spacing: 0.18em;
    margin-bottom: 1.2rem;
    font-weight: 300;
    color: #414345;
  }

  h3 {
    font-size: 26px;
    margin-bottom: 0;
    line-height: 1.2;
    color: #414345;
  }

  h2 {
    font-size: 51px;
    line-height: 1.3;
  }

  svg {
    fill: transparent;
  }

  .next-btn {
    transform: rotate(180deg);
    margin-top: 30px;
  }

  // &.hide {
  //   // opacity: 0;
  //   pointer-events: none;
  // }

  // &.show {
  //   opacity: 1;
  //   pointer-events: unset;
  // }

  img {
    width: 100%;
  }

  .content {
    display: flex;
  }

  .wrap-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .close-btn {
    top: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
    padding: 0;
  }

  .modal-md {
    width: 550px;
  }
  .modal-lg {
    width: 730px;
    .container {
      max-width: initial;
    }
  }


  //b_carousel
  @keyframes tonext {
    75% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    98% {
      opacity: 0;
    }

    99% {
      opacity: 1;
    }
  }

  @keyframes tostart {
    75% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    98% {
      opacity: 0;
    }

    99% {
      opacity: 1;
    }
  }

  @keyframes snap {
    96% {
      scroll-snap-align: center;
    }

    97% {
      scroll-snap-align: center;
    }

    99% {
      scroll-snap-align: center;
    }

    100% {
      scroll-snap-align: center;
    }
  }

  * {
    box-sizing: border-box;
    scrollbar-color: transparent transparent;
    /* thumb and track color */
    scrollbar-width: 0px;
  }

  *::-webkit-scrollbar {
    width: 0;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }

  * {
    -ms-overflow-style: none;
  }

  ol,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .b_carousel {
    position: relative;
    padding-top: 350px;
    width: 500px;
    perspective: 100px;
  }

  .b_carousel__viewport {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow-x: scroll;
    counter-reset: item;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
  }

  .b_carousel__slide {
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    counter-increment: item;
  }

  .b_carousel__slide:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -40%, 70px);
    color: #fff;
    font-size: 2em;
  }

  .b_carousel__snapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    scroll-snap-align: center;

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  // @media (hover: hover) {
  //   .b_carousel__snapper {
  //     animation-name: tonext, snap;
  //     animation-timing-function: ease;
  //     animation-duration: 4s;
  //     animation-iteration-count: 1;
  //   }

  //   .b_carousel__slide:last-child .b_carousel__snapper {
  //     animation-name: tostart, snap;
  //   }
  // }

  @media (prefers-reduced-motion: reduce) {
    .b_carousel__snapper {
      animation-name: none;
    }
  }

  .b_carousel:hover .b_carousel__snapper,
  .b_carousel:focus-within .b_carousel__snapper {
    animation-name: none;
  }

  .b_carousel__navigation {
    position: absolute;
    right: 0;
    bottom: -10px;
    left: 0;
    text-align: center;
  }

  .b_carousel__navigation-list,
  .b_carousel__navigation-item {
    display: inline-block;
  }

  .b_carousel__navigation-button {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgba(100, 100, 111, 0.1);
    background-clip: content-box;
    border: 0.25rem solid transparent;
    border-radius: 50%;
    font-size: 0;
    transition: transform 0.1s;
  }

  .b_carousel::before,
  .b_carousel::after,
  .b_carousel__prev,
  .b_carousel__next {
    position: absolute;
    top: 50%;
    width: 2rem;
    height: 2rem;
    transform: translateY(-50%);
    border-radius: 50%;
    font-size: 0;
    outline: 0;
  }

  .b_carousel::before,
  .b_carousel__prev {
    left: 0;
  }

  .b_carousel::after,
  .b_carousel__next {
    right: 0;
  }

  .b_carousel::before,
  .b_carousel::after {
    content: '';
    z-index: 1;
    background-color: transparent;
    background-size: 1.5rem 1.5rem;
    background-repeat: no-repeat;
    background-position: center center;
    color: #fff;
    font-size: 2.5rem;
    line-height: 2rem;
    text-align: center;
    pointer-events: none;
    margin-top: -0.5rem;
    opacity: 0.7;
  }

  .b_carousel::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGV2cm9uLWxlZnQiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaGV2cm9uLWxlZnQgZmEtdy0xMCBmYS0zeCAiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIwIDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMzQuNTIgMjM5LjAzTDIyOC44NyA0NC42OWM5LjM3LTkuMzcgMjQuNTctOS4zNyAzMy45NCAwbDIyLjY3IDIyLjY3YzkuMzYgOS4zNiA5LjM3IDI0LjUyLjA0IDMzLjlMMTMxLjQ5IDI1NmwxNTQuMDIgMTU0Ljc1YzkuMzQgOS4zOCA5LjMyIDI0LjU0LS4wNCAzMy45bC0yMi42NyAyMi42N2MtOS4zNyA5LjM3LTI0LjU3IDkuMzctMzMuOTQgMEwzNC41MiAyNzIuOTdjLTkuMzctOS4zNy05LjM3LTI0LjU3IDAtMzMuOTR6Ij48L3BhdGg+PC9zdmc+');
  }

  .b_carousel::after {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGV2cm9uLXJpZ2h0IiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2hldnJvbi1yaWdodCBmYS13LTEwIGZhLTN4ICIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0yODUuNDc2IDI3Mi45NzFMOTEuMTMyIDQ2Ny4zMTRjLTkuMzczIDkuMzczLTI0LjU2OSA5LjM3My0zMy45NDEgMGwtMjIuNjY3LTIyLjY2N2MtOS4zNTctOS4zNTctOS4zNzUtMjQuNTIyLS4wNC0zMy45MDFMMTg4LjUwNSAyNTYgMzQuNDg0IDEwMS4yNTVjLTkuMzM1LTkuMzc5LTkuMzE3LTI0LjU0NC4wNC0zMy45MDFsMjIuNjY3LTIyLjY2N2M5LjM3My05LjM3MyAyNC41NjktOS4zNzMgMzMuOTQxIDBMMjg1LjQ3NSAyMzkuMDNjOS4zNzMgOS4zNzIgOS4zNzMgMjQuNTY4LjAwMSAzMy45NDF6Ij48L3BhdGg+PC9zdmc+');
  }
}

button.gm-ui-hover-effect {
  width: 40px !important;
  height: 40px !important;
  top: 0 !important;
  right: 0 !important;
  &:focus-visible {
    outline: none;
  }
  img {
    width: 50% !important;
    height: 50% !important;
  }
}

.gm-ui-hover-effect>span{
  background-color: #C7A446;
  width: 20px !important;
  height: 20px !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 0 !important;
  background-color: rgba(0, 0, 0, 0.65);
  overflow-x: hidden;
  padding: 12px !important;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar{
  width: 0;
}

.gm-style .gm-style-iw-d{
  overflow: hidden!important;
}

#btn_prev_modal {
  margin-left: 4px;
}
#btn_next_modal {
  margin-right: 4px;
}

.modal-form {
  color: #000;
  font-size: 14px;
  width: 636px;
  background: #fff;
  padding: 36px 40px;
  box-shadow: -4px 5px 4px 0 rgba(#000, .25);

  &__title {
    font-size: 26px;
    line-height: 1.2em;
  }
  &__subtitle {
    // font-size: 18px;
    font-size: 16px;
    line-height: 2.188em;
    margin-bottom: 26px;
  }
  &__body {
    .form {
      &-group {
        padding-top: 13px;
        padding-bottom: 14px;
      }
      &-control {
        border-radius: 0;
        border: 0;
        height: 40px;
        padding: 6px 8px;
        line-height: 2;
        background: #F0F0F0;
        font-size: 14px;
        color: #414345;
      }
    }
    .btn-link {
      // border: 1px solid #000;
      // box-shadow: none;
      // background: #fff;
      text-decoration: underline;
      font-size: 14px;
    }
  }
}

.gm-fullscreen-control, .gmnoprint{
  display: none;
}

.body-container {
  padding-right: 40px;
  padding-left: 40px;
}
.wrap-home-gallery {
  position: absolute;
  top: 0;
  z-index: 99;
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  padding-top: 82px;
  .wrap-gallery-content {
    background-image: url('../../assets/images/BG.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    padding-bottom: 60px;
  }
  .gallery-header {
    height: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px -1px rgba(59,56,56,0.76);
    -webkit-box-shadow: 0px 0px 10px -1px rgba(59,56,56,0.76);
    -moz-box-shadow: 0px 0px 10px -1px rgba(59,56,56,0.76);
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 31px;
      line-height: 32px;
      text-align: center;
      color: #000000;
      display: flex;
      justify-content: center;

      &::after {
        position: absolute;
        margin-top: 35px;
        content: '';
        height: 2px;
        width: 60px;
        background-color: #FF674C;
      }
    }
  }
  .gallery-body {
    padding-top: 60px;
    .gallery-item {
      position: relative;
      cursor: pointer;
      .gallery-thumbnail {
        height: 150px;
        width: 100%;
        object-fit: cover;
      }
      .gallery-name {
        position: absolute;
        bottom: 15px;
        left: 15px;
        // font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 116%;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: #FFFFFF;
      }
      .gallery-type {
        position: absolute;
        right: 15px;
        bottom: 15px;
        width: 20px;
        z-index: 1000;
      }
    }
  }
}


.fancybox__track {
  width: 100vw; /* viewport width */
  height: 100vh; /* viewport height */
  position: fixed;
}


.fancybox__slide, .fancybox__content {
  width: 100%;
  height: 100%;
  background: #000000;
  padding: 0px 0px 0px 0px !important;
}


.fancybox__image {
  width: 100%;  
  height: 100%; 
  object-fit: cover !important; 
  object-position: center; 
}

.fancybox__thumbs {
  display: none;
}
.fancybox__nav {
  display: none;
}

$cms-page-bg: #fff;
$cms-page-color: #000000;
$cms-input: #ffdb00;
$menu-color: #c4a140;
#root {
  overflow: auto !important;
}

.wrap-cms-page {
  min-height: 100vh;
  overflow: auto;
  background: $cms-page-bg;

  .wrap-top-nav .nav-menu ul li.active div::before {
    opacity: 1 !important;
    // width: 97px !important;
  }

  .wrap-top-nav .nav-menu ul li:not(.placeholder) div::before {
    content: "";
    height: 1px;
    width: 0;
    background: #ffdb00;
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: all 0.2s;
  }

  .wrap-top-nav {
    position: sticky;
    background: $cms-page-color;
    .nav-menu ul li {
      opacity: 1;
      width: unset !important;
    }

    .nav-menu ul li div {
      color: $menu-color;
      font-size: 14px;
      font-family: "Radikal";
      font-weight: 500;
      letter-spacing: 0;
      font-style: normal;
      line-height: normal;
    }

    .nav-menu-cms {
      margin: 0;
      padding: 28px 0px 27px 0px;
      display: flex;
      justify-content: center;
      gap: 75px;

      .left {
        display: flex;
        align-items: flex-end;
        justify-content: end;
        gap: 75px;
      }
      .right {
        display: flex;
        align-items: flex-end;
        gap: 65px;
      }
    }
  }

  .cube__face--front {
    border-left: 2.16667px solid $cms-page-color;
    border-right: 3.25px solid $cms-page-color;
    border-bottom: 3.25px solid $cms-page-color;
    border-top: 1.625px solid $cms-page-color;
    transform: rotateY(0deg) translateZ(12px);
  }

  .cube__face--back {
    border-left: 1.625px solid #212529;
    border-right: 1.625px solid #212529;
    border-bottom: 3.25px solid #212529;
    transform: rotateY(180deg) translateZ(12px);
  }

  .cube__face--right {
    border-left: 3.25px solid #212529;
    border-right: 1.625px solid #212529;
    border-bottom: 3.25px solid #212529;
    transform: rotateY(90deg) translateZ(12px);
  }

  .cube__face--left {
    border-left: 3.25px solid #212529;
    border-right: 3.25px solid #212529;
    border-bottom: 3.25px solid #212529;
    border-top: 1.625px solid #212529;
    transform: rotateY(-90deg) translateZ(12px);
  }

  .cube__face--top {
    border-top: 3.25px solid #212529;
    border-right: 3.25px solid #212529;
    border-bottom: 3.25px solid #212529;
    border-left: 3.25px solid #212529;
    transform: rotateX(90deg) translateZ(12px);
  }

  .cube__face {
    background: white;
  }

  .page-container {
    position: relative;
    min-height: calc(100vh - 100px);
  }

  .page-header {
    background: $cms-page-color;
    padding: 70px 150px 50px 150px;

    img {
      cursor: pointer;
    }
    .f-title {
      color: #fff;
      font-size: 48px;
      font-family: "Radikal";
      font-weight: 300;
      text-transform: capitalize;
    }

    &__input-search {
      max-width: 450px;

      .form-control {
        color: #414345;
        font-size: 14px;
        border: 8px;
        border-bottom: 2px solid $cms-page-color;
        border-radius: 0;
        padding-left: 10px;
        height: 46px;
        margin-right: 10px;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          color: $cms-page-color;
        }
      }
    }

    .btn {
      background: $cms-page-bg;
      color: $cms-page-color;
      &.sort-desc svg {
        transform: rotate(180deg);
      }
    }
    button:focus {
      outline: none;
    }
    button:focus:not(:focus-visible) {
      outline: 0;
      box-shadow: none;
    }
    .btn-update-profile {
      border-radius: 39px;
      border: 1px solid #fff;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      color: #ffffff;
      font-size: 16px;
      font-family: "Radikal";
      font-weight: 600;
      padding: 12px 19px;
    }
    .btn-options {
    }
    .btn-customer {
      margin-left: auto;
      margin-right: 10px;
      color: #fff;
      font-family: OpenSans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .page-body {
    position: relative;
    .table > :not(caption) > * > * {
      border-bottom-width: 0px;
    }

    .table-striped > tbody > tr:nth-of-type(odd) {
      --bs-table-accent-bg: $cms-page-bg;
    }

    .table-responsive-custom {
      height: calc(100vh - 400px);
      .table-custom {
        width: 100%;
      }
      thead {
        background: $cms-page-bg;
        color: $cms-page-color;
        text-transform: uppercase;
        border: 1px solid $cms-page-color;
        @media only screen and (max-width: 1366px) {
          .header-tenancy {
            padding-right: 30px;
          }
          .header-expiry {
            padding-right: 100px;
          }
          .header-level,
          .header-training,
          .header-precinct {
            padding-bottom: 45px;
          }
        }
        tr {
          display: "table";
          width: "100%";
          table-layout: "auto";
        }
        th {
          font-weight: 500;
          border-right: 1px solid #000000;
          padding: 20px 0;
          .btn-edit {
            background: $cms-page-bg;
            color: $cms-page-color;
            padding: 0 10px 4px;
            font-size: 12px;
            font-weight: 700;
            line-height: 8px;
            position: relative;
            border: 0px;
            left: 10px;
            bottom: 3px;
          }
        }
      }
      tbody {
        background: $cms-page-bg;
        border-left: 1px solid $cms-page-color;
        border-bottom: 1px solid $cms-page-color;
        border-right: 1px solid $cms-page-color;

        @media only screen and (max-width: 1366px) {
          height: calc(100vh - 500px);
        }

        tr {
          td {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: $cms-page-color;
            .btn-more {
              line-height: 6px;
              padding: 0 8px 6px;
              margin-left: 30%;
              background: #f0f0ea;

              &:focus {
                box-shadow: none;
              }
            }

            border-right: 0.5px solid black;
            padding: 10px 5px 10px 5px;

            .custom-input {
              position: relative;
              border: none;
              background: $cms-input;
              width: 100%;
              height: 25px;
              transform: translate(-2px, 0);
              // cursor: text;

              &:focus {
                outline: none;
              }
            }
          }

          .clickable {
            cursor: pointer;
          }

          .text-one-line {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          td:last-of-type {
            border-right: unset;
          }
        }
      }
    }

    .table-responsive-custom-cms {
      margin-top: 59px;
      margin-bottom: 60px;
      padding-right: 150px;
      padding-left: 150px;
      .table-cms {
        border-collapse: collapse;
        width: 100%;
        th {
          text-align: left;
          white-space: nowrap;

          /* Form text */
          font-family: Radikal;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          color: rgba(0, 0, 0, 0.45);
          font-family: Radikal;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%;
          padding-bottom: 35px;
          border: none;
          text-transform: capitalize;
          .btn-edit {
            background: $cms-page-bg;
            color: $cms-page-color;
            font-size: 12px;
            font-weight: 700;
            line-height: 8px;
            position: relative;
            border: 0;
            left: 10px;
            bottom: 3px;
          }
        }
        tr td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        td {
          border: none;
          text-align: left;
          white-space: nowrap;
          height: 38px;
          font-size: 14px;
          padding: 2.5px;

          /* Form text */
          font-family: Radikal;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          color: #000;
        }

        tr {
          td {
            .custom-input {
              position: relative;
              border: none;
              background: $cms-input;
              width: 100%;
              height: 25px;
              transform: translate(-2px, 0);
              &:focus {
                outline: none;
              }
            }
            .form-check-input {
              width: 55px;
              height: 25px;
              background-color: #e9e9e9;
              border: #e9e9e9 1px solid;
              background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23000'/%3E%3C/svg%3E");
              box-shadow: none;
              cursor: pointer;
              &:checked {
                border: black 1px solid;
                background-color: white;
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23000'/%3E%3C/svg%3E");
              }
            }
          }
        }
        .menu-table-cms {
          th {
            color: rgba(0, 0, 0, 0.45);
            font-family: Radikal;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 140%;
            padding-bottom: 35px;
            border: none;
          }
        }
      }
    }

    .line-info-profile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 10px;
      margin-bottom: 20px;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
        display: flex;
        align-items: flex-end;
        color: rgba(0, 0, 0, 0.45);
        flex: none;
        order: 0;
        flex-grow: 0;
      }
      span:last-child {
        margin-top: 5px;
        color: #000000;
      }
    }

    .wrapper-list-faq {
      overflow-y: scroll;
      height: calc(100vh - 450px);
    }

    .line-faq {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 10px;
      margin-bottom: 20px;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
        display: flex;
        align-items: flex-end;
        color: rgba(0, 0, 0, 0.45);
        flex: none;
        order: 0;
        flex-grow: 0;
      }
      span:last-child {
        margin-top: 5px;
        color: #000000;
      }
    }

    .tab-faqs {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 55px;
      span {
        padding: 0 10px;
        border-bottom: 1px solid #0c2032;
        cursor: pointer;
      }
      .isActive {
        border-bottom: 2px solid #0c2032;
      }
    }
  }

  .page-footer {
    margin-top: 20px;
    padding: 0 75px;

    .btn {
      &:focus {
        box-shadow: none;
      }
    }
  }

  .btn-outline {
    border: none;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #ffffff;
    color: #000000;
    opacity: 0.5;
    padding: 0;
  }

  .modal-over-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;

    .modal-notes {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      padding: 34px 40px;
      background-color: $cms-page-bg;
      max-width: 700px;
      width: 100%;
      margin: auto;

      .modal-header {
        padding: unset;
        border: unset;

        .f-title {
          font-size: 28px;

          &::before {
            content: "";
            position: absolute;
            width: 100px;
            top: 80px;
            border-bottom: 2px solid $cms-page-color;
          }
        }
      }

      .modal-body {
        padding: 36px 134px 78px 0;
        border: unset;

        .modal-body-text {
          font-size: 18px;
          font-weight: 400;
          line-height: 1.2;
          border: unset;
          width: 100%;
          outline: unset;
        }
      }

      .modal-footer {
        border: unset;
        display: flex;
        justify-content: flex-start;
      }

      .close-btn {
        position: absolute;
        top: 0;
        right: 0;

        border: unset;
        background-color: unset;
        color: $cms-page-color;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.5;
        padding: 16px;
        line-height: 15px;
        font-weight: 300;

        &:hover {
          background-color: $cms-page-bg;
        }
      }
    }
  }
  .cube-scene {
    width: 105px;
    height: 32px;
  }
}
.wrap-content-page {
  .nav-tabs {
    border-bottom: 0;
    display: flex;
    justify-content: center;

    .nav-link {
      border: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 15px;
      color: #000000;
      display: flex;
      justify-content: center;
      border-bottom: 3px solid #000000;
      &:hover {
        border-color: #bbefff;
      }
      &.active {
        color: #000000;
        border-color: #bbefff;
      }
    }
  }
}
.wrap-content-image {
  padding-bottom: 20px;
  .page-body {
    .wrap-media-list {
      border: 1px solid #000000;
      padding: 64px 26px;
      overflow-y: scroll;
      max-height: calc(100vh - 400px);
      .wrap-media-item {
        height: 200px;
        .media-image {
          height: 150px;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        .media-details {
          .media-title {
            margin-top: 13px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
            margin-bottom: 13px;
          }
          .media-edit-btn {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
          }
        }
      }
    }
    .wrap-media-actions {
      .btn-add-new-media {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        text-decoration-line: underline;
        color: #000000;
      }
    }
  }
}
.wrap-content-gallery {
  padding-bottom: 20px;
  .page-body {
    .wrap-gallery-list {
      border: 1px solid #000000;
      padding: 64px 26px;
      overflow-y: scroll;
      max-height: calc(100vh - 400px);
      .wrap-gallery-item {
        height: 200px;
        .gallery-image {
          height: 150px;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        .gallery-details {
          .gallery-title {
            margin-top: 13px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
            margin-bottom: 13px;
          }
          .gallery-edit-btn {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #000000;
          }
        }
      }
    }
    .wrap-gallery-actions {
      .btn-add-new-gallery {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        text-decoration-line: underline;
        color: #000000;
      }
    }
  }
}

.modal-request-support {
  .modal-base-content {
    width: 640px;
    .modal-base-body {
      width: 634px;
      overflow-y: hidden;
      padding: 40px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      height: auto;
      max-height: 90vh;
      .modal-form__title {
        font-family: "Radikal";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        color: #000000;
      }
      .wrapper-list-user {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .wrapper-info {
          display: flex;
          gap: 10px;
        }

        .form-label {
          color: rgba(0, 0, 0, 0.45);
          margin-bottom: 5px !important;
          /* Form text */
          font-family: Radikal;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
        .form-label.error {
          color: red;

          /* Form text */
          font-family: Radikal;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }

        .form-control:focus {
          border: 2px solid #000;
          box-shadow: none;
          padding: 15px 18px 11px 18px; // minus 1 for border
        }

        .form-control.error {
          border: 2px solid red;
          box-shadow: none;
          padding: 15px 18px 11px 18px; // minus 1 for border
        }

        .error-text {
          color: red;

          font-family: Radikal;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }

        input {
          border-radius: unset;
          padding: 16px 19px 12px 19px;
          color: #000;

          /* Body text */
          font-family: Radikal;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%; /* 22.4px */
        }
        input::placeholder {
          color: rgba(0, 0, 0, 0.45);

          /* Body text */
          font-family: Radikal;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%; /* 22.4px */
        }
        .form-info {
          display: flex;
          flex-direction: column;
          label {
            color: rgba(0, 0, 0, 0.45);
            font-family: "Radikal";
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
          input,
          textarea {
            padding: 16px 19px 12px 19px;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border: 1px solid rgba(0, 0, 0, 0.1);
          }
          textarea {
            height: 150px;
          }
        }
      }
      .wrap-button-list-user {
        button {
          height: 50px;
          width: 100px;
          display: flex;
          padding: 16px 19px 12px 19px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          text-transform: uppercase;

          /* Body text */
          font-family: Radikal;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%; /* 22.4px */
        }

        .submit-button {
          color: white;
          border: 2px solid #c9c9c9;
          background: #c9c9c9;
        }

        .dirty {
          border: 2px solid #c7a446;
          background: #c7a446;
        }

        .close-button {
          border: none;
          background-color: white;
          color: black;
          font-size: 14px;
        }
      }
    }
  }
}

.modal-favorites {
  .modal-base-content {
    min-width: 700px;
    max-width: none;
    .modal-base-body {
      overflow: auto;
      padding: 40px;
      height: calc(567px + 80px);
      .header-favorite {
        text-transform: capitalize;
        white-space: nowrap;
        .title {
          color: #000;
          font-family: "Radikal";
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
        .wrapper-table {
          margin-top: 40px;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 163px;
          table {
            width: 1066px;
            thead {
              tr {
                th {
                  color: rgba(0, 0, 0, 0.45);
                  font-family: "Radikal";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 140%;
                }
              }
            }
            .spacing-header {
              height: 35px;
              width: 1px;
            }
            tbody {
              margin-top: 35px;
              tr {
                height: 32px;
                border-bottom: solid 1px rgba(0, 0, 0, 0.1);
                td {
                  color: #000;
                  /* Form text */
                  font-family: Radikal;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
      .body-favorite {
        margin-top: 40px;
        .title {
          color: rgba(0, 0, 0, 0.45);
          font-family: "Radikal";
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%;
          text-transform: capitalize;
        }
        .media-list {
          margin-top: 40px;
          gap: 15px;
          display: grid;
          grid-template-columns: auto auto auto;
          .item-image {
            img {
              width: 345px;
              height: 225px;
            }
          }
        }
      }
    }
  }
}

.modal-dialog {
  max-width: 634px !important;
}

.edit-property-modal {
  padding: 40px !important;
  border: #000;
  border-radius: 8px;
  width: 634px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .form-control:focus {
    border: 2px solid #000;
    box-shadow: none;
    padding: 15px 18px 11px 18px; // minus 1 for border
  }

  .form-control.error {
    border: 2px solid red;
    box-shadow: none;
    padding: 15px 18px 11px 18px; // minus 1 for border
  }

  .error-text {
    color: red;

    font-family: Radikal;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .modal-title {
    margin-bottom: 20px;
    color: #000;

    /* Header */
    font-family: Radikal;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 33.6px */
  }

  .row {
    padding-top: 20px;
    --bs-gutter-x: 10px !important;
  }

  .form-label {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 5px !important;
    /* Form text */
    font-family: Radikal;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .form-label.error {
    color: red;

    /* Form text */
    font-family: Radikal;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  input {
    border-radius: unset;
    padding: 16px 19px 12px 19px;
    color: #000;

    /* Body text */
    font-family: Radikal;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 22.4px */
  }
  input::placeholder {
    color: rgba(0, 0, 0, 0.45);

    /* Body text */
    font-family: Radikal;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 22.4px */
  }

  .submit-button {
    color: white;
    border: 2px solid #c9c9c9;
    background: #c9c9c9;
  }

  .dirty {
    border: 2px solid #c7a446;
    background: #c7a446;
  }

  .close-button {
    border: none;
    background-color: white;
    color: black;
    font-size: 14px;
  }

  button {
    height: 50px;
    width: 100px;
    display: flex;
    padding: 16px 19px 12px 19px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    text-transform: uppercase;

    /* Body text */
    font-family: Radikal;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 22.4px */
  }
}

.modal {
  background: rgba(0, 0, 0, 0.5);
}

.edit-property-button {
  width: 31px;
  height: 13px;
  flex-shrink: 0;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-width: 0px;

  span {
    color: #000;
    font-family: "Centra No1 TRIAL";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
    display: flex;
    width: 17px;
    height: 10px;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;
  }
}

.edit-property-modal-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.unique-url-column-wrapper {
  display: flex;
  .unique-url-column {
    display: flex;
    padding-left: 10px;
    align-items: center;
    gap: 10px;
    border-radius: 39px;
    background: rgba(0, 0, 0, 0.05);
    .unique-url-column-text {
      text-overflow: ellipsis;
      width: 142px;
      overflow: hidden;
    }
    .unique-url-column-copy-button {
      display: flex;
      height: 28px;
      padding: 12px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 39px;
      background: #000;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }
}

.close-button-x {
  position: fixed;
  top: 48px;
  right: 48px;
  cursor: pointer;
}

.wrap-floorplan-gallery {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 60;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #5b5b5b;

  .slick-slider {
    max-height: 100vh;
    overflow: hidden;

    .floorplan-item {
      width: 100vw;
      height: 100vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background-color: #000000;
    }
  }
}

.wrap-list-floor {
  .list-group {
    position: absolute;
    width: 100%;
    bottom: 0;

    .list-group-item {
      text-decoration: underline;
      font-size: 14px;
      color: #000;
      opacity: 1;
      font-weight: 300;
      text-transform: uppercase;
      cursor: pointer;
      &.active {
        font-weight: bold;
      }
    }
  }
}

.wrap-control-btns {
  position: absolute;
  bottom: 66px;
  z-index: 1000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    cursor: pointer;
  }
  .control-button{
    margin-left: 10px;
    margin-right:10px;
  }
}


.wrap-close-btn{
  position: absolute;
  bottom: 100px;
  z-index: 1000;
  width: 100%;
  padding: 0 36px;
  display: flex;
  justify-content: space-between;
  div{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    span{
      color: #C7A446;
      font-family: 'Radikal';
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
    img {
      margin-right: 10px;
    }
  }

}

.wrap-upload-media-modal {
  .modal-content {
    border-radius: 0px;
  }

  .content {
    margin-bottom: 32px;

    .form-control {
      border-radius: 0px;
    }
  }

  .modal-form__title {
    font-size: 20px;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: bold !important;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #000000;
    margin-bottom: 14px;
  }

  button {
    border: 0px;
    padding: 0px;
    text-decoration: underline;
  }

  .media-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
}

.wrap-edit-media-modal {
  .modal-content {
    border-radius: 0px;
  }

  .content {
    margin-bottom: 32px;

    .form-control {
      border-radius: 0px;
    }
  }

  .modal-form__title {
    font-size: 20px;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: bold !important;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #000000;
    margin-bottom: 14px;
  }

  button {
    border: 0px;
    padding: 0px;
    text-decoration: underline;
  }

  .media-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
}

.hidden {
  visibility: hidden;
}

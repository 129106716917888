@import '../../common';

.wrap-sidenav {
  position: absolute;
  z-index: 111;
  width: fit-content;
  height: calc(100vh - 200px);
  top: 90px;
  left: 0;
  color: #000;
  @media only screen and (max-width: 1366px) {
    height: calc(100vh - 180px);
  }
  .sidenav {
    width: 225px;
    padding: 15px;
    background: rgba(0, 0, 0, 0.65);
  }

  > div {
    height: 100%;
  }

  .sidenav__heading {
    padding: 0;
    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 61px;
      margin-bottom: 10px;
    }
    .header-title{
      color: #C7A446;
      font-family: 'Radikal';
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }

  .sidenav__body {
    justify-content: start;
    line-height: 2.188;
  }

  .sidenav--dark {
    background: rgba(#343434, 0.9);

    .list-group-item {
      &:hover {
        opacity: 1;
      }
    }
    .list-group-heading {
      color: #ababab;
    }
  }

  .wrap-btn-detail .list-group .list-group-item,
  .list-group-item {
    padding: 0 .75rem;
  }
  .list-group-item {
    font-weight: 400;
    color: #fff;
    &.list-group-heading {
      font-weight: 500;
    }
    &.active {
      opacity: 1;
      color: #C7A446;
      border-radius: 0;
      font-weight: 400;
    }
  }
  .list-group-heading {
    cursor: initial;
    flex-basis: 100%;
    letter-spacing: 0.12em;
  }
  .list-group-horizontal .list-group-item:not(.list-group-heading) {
    flex-basis: 30%;
  }
  #filter {
    background: rgba(0, 0, 0, 0.65);

    .sidenav__body {
      padding-right: 5px;
    }

    .filter-group {
      .heading {
        color: #C7A446;
        font-family: 'Radikal';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
      }

      .list-group {
        .list-group-item {
          border-radius: unset;
          border: solid 1px #C7A446;
        }

        .list-group-item {
          &.active {
            opacity: 1;
            background-color: #C7A446;
            color: #FFFFFF;
            border-radius: 0;
            font-weight: 400;
          }
        }
      }

      .form-control {
        background: transparent;
        border: solid 1px #C7A446;
        color: #fff;
      }

      .form-control::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .wrapper-button{
      position: absolute;
      bottom: 20px;
      padding-left: 0.75rem;
      width: 100%;
      left: 0;
      z-index: 100;
      flex-direction: column;
      .btn-bottom{
        cursor: pointer;
        span{
          width: 74px;
          height: 15px;
          color: #C9C9C9;
          font-family: 'Radikal';
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          margin-left: 10px;
        }
        .txt-active{
          color: #C7A446;
        }
      }
    }

    .active-btn{
      pointer-events: none;
    }
    .unActive-btn{
      pointer-events: visible;
    }
  }
  #lots {
    .wrap-show-filter-btn {
      position: absolute;
      bottom: 20px;
      padding-left: 0.75rem;
      width: 100%;
      left: 0;
      z-index: 100;
     .btn-bottom{
       cursor: pointer;
       .rotate-img{
         transform: rotate(180deg);
       }
       span{
         width: 76px;
         height: 15px;
         color: #C9C9C9;
         font-family: 'Radikal';
         font-size: 14px;
         font-style: normal;
         font-weight: 300;
         line-height: normal;
         margin-left: 10px;
       }
       .txt-active{
         color: #C7A446;
       }
     }
    }
    .list-group {
      .list-group-item {
        padding: 0;
      }
    }
  }
  .list-unit-detail{
    list-style-type: none;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    .item-detail{
      display: flex;
      flex-direction: column;
      border-bottom: solid 1px #C9C9C9;
      padding-bottom: 15px;
      margin-bottom: 15px;
      span{
        color: #C9C9C9;
        font-family: 'Radikal';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
      b{
        margin-top: 5px;
        color: #FFF;
        font-family: 'Radikal';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
      }
    }
  }
}

.wrap-sidenav--right {
  left: unset;
  right: 0;
}

.wrap-btn-detail {
  width: 100%;
  text-align: left;
  color: #fff;

  .list-group {
    .list-group-item {
      font-family: 'Radikal';
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      cursor: pointer;
      color: #C4A140;
      margin-bottom: 5px;
      img{
        transform: rotate(180deg);
        margin-right: 10px;
      }
    }
  }
}

.wrap-btn-show-filter {
  font-size: 11px;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2rem;
  z-index: 1120;

  p {
    margin: 0;
    b {
      color: #fff;
      text-decoration: underline;
    }
  }
}

#sidenavDetail {
  width: 285px;
  .sidenav {
    &__body {
      .list-group-item {
        opacity: 1;
      }
      & > .list-group .list-group-item {
        cursor: initial;
      }
    }
  }
  .sidenav__heading h2 {
    padding-left: .5rem;
    padding-right: .5rem;
    font-size: 24px;
  }
}

.accordion-filter {
  .list-group-heading {
    font-size: 12px;
    &::after {
      content: '+';
      display: inline-block;
      margin-left: .5rem;
      margin-top: -3px;
    }
    &.open::after {
      content: '-';
    }
  }
}
.text-gray {
  color: #ababab;
}
.scroll-unit{
  overflow-y: scroll;
  height: 95%;
}
.scroll-filter{
  overflow-y: scroll;
  height: 88%;
}

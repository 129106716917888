@import './common';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0C2032;
  overflow: hidden;
}

.bg,
canvas {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  cursor: grab;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  background-color: #4e4d4b;
}

.page {
  background-color: #eee;
  /* background-image: url("https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg"); */
  min-height: 100vh;
  /* background-repeat: no-repeat;
  background-size: 100% 100%; */
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888850;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #55555550;
}

/* fade in/out animation */
.fade-item-enter {
  opacity: 0;
}

.fade-item-enter-active {
  opacity: 1;
  transition: opacity 125ms ease-in;
}

.fade-item-exit {
  opacity: 1;
}

.fade-item-exit-active {
  opacity: 0;
  transition: opacity 125ms ease-in;
}

/* fade left animation */
.fade-left-enter {
  margin-left: -100%;
  opacity: 0;
}

.fade-left-enter-active {
  margin-left: 0;
  opacity: 1;
  transition: all 500ms linear;
}

.fade-left-exit {
  margin-left: 0;
  opacity: 1;
}

.fade-left-exit-active {
  margin-left: -100%;
  opacity: 0;
  transition: all 500ms linear;
}

/* fade right animation */
.fade-right-enter {
  right: -100%;
  opacity: 0;
}

.fade-right-enter-active {
  right: 0;
  opacity: 1;
  transition: all 500ms linear;
}

.fade-right-exit {
  right: 0;
  opacity: 1;
}

.fade-right-exit-active {
  right: -100%;
  opacity: 0;
  transition: all 500ms linear;
}

.rs-base.hide {
  display: none;
}

.static-page{
  width: 100vw;
  background: #0C2032;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  .scroll-view{
    overflow-y: scroll;
    height: 100vh;
    width: 100%;
  }
  .image-page{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    span{
      font-family: 'RadikalRegular';
      font-size: 66px;
      line-height: 100%;
      text-align: center;
      color: #FFFFFF;
      max-width: 80vw;
    }
  }
  .content-page{
    padding: 100px 150px;
    display: flex;
    flex-direction: column;
    span{
      font-family: 'RadikalRegular';
      width: 380px;
    }
    .title-bottom{
      font-size: 48px;
      line-height: 60px;
      color: #C7A446;
      margin-bottom: 37px;
    }
    .content-bottom{
      font-size: 16px;
      line-height: 140%;
      color: #FFFFFF;
    }
  }
}

.t-12{
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.w700{
  font-weight: 700;
}

.w600{
  font-weight: 600;
}

.linear-top{
  background: linear-gradient(180deg, #00000091 0%, rgba(0, 0, 0, 0) 80%);

}
.linear-bottom{
  background: linear-gradient(360deg, #00000091 0%, rgba(0, 0, 0, 0) 80%);
}

.hide-bottom{
  display: none;
}

.replay-video{
  width: 130px;
  height: 50px;
  border-radius: 39px;
  border: solid 2px #ffffff;
  font-size: 16px;
  font-family: "Radikal";
  font-weight: 300;
  background-color: rgba(255,255,255,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.searchPage{
  .title-search{
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    color: #C7A446;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .search-input{
    height: 50px;
    border: 1px solid #E4D8B2;
    width: 100%;
    flex-direction: row;
    display: flex;
    margin: 40px 0;
    input{
      height: 100%;
      width: 100%;
      border: none;
      padding: 16px 0 16px 23px;
    }
    button{
      border: 2px solid #C7A446;
      text-transform: uppercase;
      background: #C7A446;
      font-weight: 300;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: #FFFFFF;
      flex: none;
      &:hover{
        background: #C9C9C9;
        border: 2px solid #C9C9C9;
      }
    }
  }
  .wrapper{
    overflow-y: scroll;
    height: 450px;
    .item-search{
      cursor: pointer;
    }
    .card-search{
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 20px;
      margin-bottom: 20px;
      background: #FEF8EF;
      span{
        color: #000000;
      }
      .title{
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
        display: flex;
        align-items: flex-end;
        color: #104C49;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
      .textSub{
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
        display: flex;
        align-items: flex-end;
        color: #000000;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }
    }
  }
}

.modal-search{
  //background: rgb(37,37,37) !important;
  .btn-close-modal{
    top: 45px;
  }
  .modal-base-body{
    height: 650px !important;
    width: 900px !important;
    background: #FEF8EF !important;
    overflow: hidden;
    @media only screen and (max-width: 600px) {
      width: 400px !important;
    }
  }
}

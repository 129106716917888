.gallery-landing {
  position: absolute;
  top: 0;
  padding-top: 123px !important;
  flex-direction: column !important;
  height: 100vh;

  .gallery-landing-wrapper {
    height: 100%;
  }
  .tab-wrapper {
    width: 100%;
    //padding: 5px 0;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .main-tab {
      display: inline-flex;
      justify-content: center;
      align-items: flex-start;
      gap: 50px;

      .main-tab-item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .main-tab-text {
          color: #C7A446;
          text-align: right;
          font-size: 16px;
          font-family: Radikal;
          font-weight: 300;
          line-height: 140%;
        }

        .main-tab-text.active {
          color: #C7A446;
          text-align: right;
          font-size: 16px;
          font-family: Radikal;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }

    .secondary-tab {
      margin-top: 30px;
      display: inline-flex;
      padding: 4px 0px;
      align-items: flex-start;

      .secondary-tab-wrap-item {
        cursor: pointer;

        .secondary-tab-item {
          padding: 0px 20px;

          .secondary-tab-text {
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-family: Radikal;
            font-weight: 300;
          }

          .secondary-tab-text.active {
            font-family: Radikal;
            font-weight: normal;
          }
        }

        .secondary-tab-border {
          height: 2px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .secondary-tab-border-line {
            background: #FFF;
            height: 1px;
          }

          .secondary-tab-border-line.active {
            height: 2px;
          }
        }
      }
    }

  }

  .gallery-content {
    height: 100%;
    //margin-bottom: 60px;
  }
}

::-webkit-scrollbar {
  display: none;
}

.aboutPage {
  padding-bottom: 80px;

  .scroll-view {
    overflow-x: hidden;
  }

  .layout {
    position: absolute;
    width: 100%;
    max-width: 1366px;
    min-width: 1194px;
  }

  .about-1 {
    position: relative;
    width: 100%;
    height: 769px;

    .overlay {
      opacity: 0.125;
      background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
      mix-blend-mode: multiply;
      height: 769px;
      flex-shrink: 0;
      position: absolute;
      top: 0;
      pointer-events: none;
      z-index: 10000;
    }

    .text {
      display: block;
      position: absolute;
      width: 378px;
      color: #fff;

      /* Body text */
      font-family: Radikal;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
      /* 22.4px */
      top: 511px;
      left: 62px;
    }

    .image {
      background:
        url("../../assets/images/about-1.png"),
        lightgray 50% / cover no-repeat;
      background-size: cover;
      height: 768px;
      width: 100%;
      position: absolute;
      z-index: 1;
    }
  }

  .about-2 {
    width: 100%;
    height: 922px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    justify-content: center;

    .bg {
      width: 100%;
      height: 943px;
      margin-bottom: -21px;
      flex-shrink: 0;

      .bg-image {
        width: 100%;
        height: 983.52px;
        position: absolute;
        top: -20px;
        bottom: -21px;
        flex-shrink: 0;
        background:
          url("../../assets/images/about-2-bg.png"),
          lightgray 50% / cover no-repeat;
        background-size: cover;
      }

      .bg-overlay {
        width: 100%;
        height: 1024px;
        position: absolute;
        top: -40px;
        bottom: -41px;
        flex-shrink: 0;
        background: rgba(12, 32, 50, 0.98);
        mix-blend-mode: multiply;
      }
    }

    .text {
      position: absolute;
      top: 250px;
      left: 62px;
      display: flex;
      width: 350px;
      flex-direction: column;
      align-items: flex-start;
      gap: 37px;

      .title {
        align-self: stretch;
        color: #c7a446;

        /* Brochure header */
        font-family: Radikal;
        font-size: 42px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
      }

      .content {
        align-self: stretch;
        color: #fff;

        /* Body text */
        font-family: Radikal;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%; /* 22.4px */
      }
    }

    .image {
      position: absolute;
      top: 75px;
      left: 523px;
      width: 843px;
      height: 772px;
      flex-shrink: 0;
      background:
        url("../../assets/images/about-2.png"),
        lightgray -386.431px 0px / 287.528% 100% no-repeat;
      background-size: cover;
      background-position-x: -386.431px;
    }
  }

  .about-3 {
    width: 100%;
    height: 836px;
    position: relative;

    .overlay {
      position: absolute;
      width: 100%;
      height: 944px;
      flex-shrink: 0;
      opacity: 0.125;
      background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
      mix-blend-mode: multiply;
    }

    .image {
      width: 100%;
      height: 836px;
      position: absolute;
      flex-shrink: 0;
      background:
        url("../../assets/images/about-3.png"),
        lightgray 50% / cover no-repeat;
      background-size: cover;
    }

    .logo {
      position: absolute;
      top: 130px;
      right: 62px;
      width: 150px;
      height: 79.545px;
      flex-shrink: 0;
      background:
        url("../../assets/images/about-3-logo.png"),
        -38.636px -68.182px / 151.515% 285.714% no-repeat;
      background-size: cover;
    }

    .text {
      position: absolute;
      width: 100%;
      height: 100%;

      .title {
        position: absolute;
        bottom: 62px;
        left: 62px;
        width: 346px;
        flex-shrink: 0;
        color: #fff;

        /* Brochure header */
        font-family: Radikal;
        font-size: 42px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
      }

      .content {
        position: absolute;
        width: 570px;
        bottom: 62px;
        right: 62px;
        flex-shrink: 0;
        color: #fff;

        /* Body text */
        font-family: Radikal;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%; /* 22.4px */
      }
    }
  }

  .about-4-5-6 {
    display: flex;
    width: 100%;
    height: 2335px;
    padding: 75px 62px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .overlay {
      width: 100%;
      height: 2335px;
      position: absolute;
      top: 0;
      left: 0;
      background: url("../../assets/images/about-4-5-6-bg.png");
      background-size: cover;
    }

    .layout {
      width: 100%;
      min-width: 1194px;
      max-width: 1366px;
      display: flex;
      gap: 100px;
      flex-direction: column;
      padding-top: 75px;
      padding-bottom: 75px;
      padding-left: 62px;
      padding-right: 62px;
    }
    .about-4 {
      display: flex;
      align-items: center;
      align-self: stretch;
      position: relative;
      height: 559px;
      width: 100%;
      gap: 100px;

      .text {
        display: flex;
        width: 350px;
        flex-direction: column;
        align-items: flex-start;
        gap: 37px;

        .title {
          align-self: stretch;
          color: #c7a446;

          /* Brochure header */
          font-family: Radikal;
          font-size: 42px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          text-transform: uppercase;
        }

        .content {
          align-self: stretch;
          color: #fff;

          /* Body text */
          font-family: Radikal;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%; /* 22.4px */
        }
      }

      .image {
        width: 854px;
        height: 559.187px;
        background:
          url("../../assets/images/about-4.png"),
          lightgray 50% / cover no-repeat;
        background-size: cover;
        margin-right: -62px;
      }
    }

    .about-5 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 100px;
      align-self: stretch;
      position: relative;

      .image {
        width: 804px;
        height: 804px;
        background:
          url("../../assets/images/about-5.png"),
          lightgray 50% / cover no-repeat;
        background-size: cover;
        margin-left: -62px;
      }

      .text {
        display: flex;
        width: 400px;
        flex-direction: column;
        align-items: flex-start;
        gap: 37px;

        .title {
          align-self: stretch;
          color: #c7a446;

          /* Brochure header */
          font-family: Radikal;
          font-size: 42px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          text-transform: uppercase;
        }

        .content {
          align-self: stretch;
          color: #fff;

          /* Body text */
          font-family: Radikal;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%; /* 22.4px */
        }
      }
    }

    .about-6 {
      display: flex;
      width: 1304px;
      align-items: center;
      gap: 100px;
      position: relative;

      .text {
        display: flex;
        width: 375px;
        flex-direction: column;
        align-items: flex-start;
        gap: 37px;
        flex-shrink: 0;

        .title {
          color: #c7a446;

          /* Brochure header */
          font-family: Radikal;
          font-size: 42px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          text-transform: uppercase;
        }

        .content {
          align-self: stretch;
          color: #fff;

          /* Body text */
          font-family: Radikal;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%; /* 22.4px */

          .bold {
            color: #fff;

            /* Body text - regular */
            font-family: Radikal;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
          }
        }
      }

      .image {
        width: 829px;
        height: 622.164px;
        flex-shrink: 0;
        background:
          url("../../assets/images/about-6.png"),
          lightgray 50% / cover no-repeat;
        background-size: cover;
      }
    }
  }

  .about-7 {
    width: 100%;
    height: 858px;
    position: relative;

    .image {
      width: 100%;
      height: 857px;
      position: absolute;
      flex-shrink: 0;
      background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        url("../../assets/images/about-7.png"),
        lightgray 0px 0.393px / 100% 119.545% no-repeat;
      background-size: cover;
    }

    .logo {
      width: 150px;
      height: 120px;
      position: absolute;
      left: 62px;
      top: 62.8px;
      flex-shrink: 0;
      background:
        url("../../assets/images/about-7-logo.png"),
        50% / cover no-repeat;
      background-size: cover;
    }

    .text {
      display: flex;
      width: 350px;
      flex-direction: column;
      align-items: flex-start;
      gap: 37px;
      position: absolute;
      top: 369px;
      right: 62px;

      .title {
        align-self: stretch;
        color: #fff;

        /* Brochure header */
        font-family: Radikal;
        font-size: 42px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
      }

      .content {
        align-self: stretch;
        color: #fff;

        /* Body text */
        font-family: Radikal;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%; /* 22.4px */
      }
    }
  }

  .about-8-9 {
    display: flex;
    width: 100%;
    height: 1200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .layout {
      width: 100%;
      min-width: 1194px;
      max-width: 1366px;
      display: flex;
      gap: 100px;
      flex-direction: column;
      padding: 75px 62px;
    }

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background:
        url("../../assets/images/about-8-9-bg.png"),
        lightgray 50% / cover no-repeat;
      background-size: cover;
    }

    .about-8 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 100px;
      align-self: stretch;
      position: relative;

      .image {
        width: 885px;
        height: 497.813px;
        background:
          url("../../assets/images/about-8.png"),
          lightgray 50% / cover no-repeat;
        margin-left: -62px;
        background-size: cover;
      }

      .text {
        display: flex;
        width: 320px;
        flex-direction: column;
        align-items: flex-start;
        gap: 37px;

        .title {
          color: #c7a446;

          /* Brochure header */
          font-family: Radikal;
          font-size: 42px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          text-transform: uppercase;
        }

        .content {
          color: #fff;

          /* Body text */
          font-family: Radikal;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%; /* 22.4px */
        }
      }
    }

    .about-9 {
      display: flex;
      align-items: center;
      gap: 100px;
      align-self: stretch;
      position: relative;

      .text {
        display: flex;
        width: 400px;
        flex-direction: column;
        align-items: flex-start;
        gap: 37px;

        .title {
          align-self: stretch;
          color: #c7a446;

          /* Brochure header */
          font-family: Radikal;
          font-size: 42px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          text-transform: uppercase;
        }

        .content {
          align-self: stretch;
          color: #fff;

          /* Body text */
          font-family: Radikal;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%; /* 22.4px */
        }
      }

      .image {
        width: 804px;
        height: 452.25px;
        background:
          url("../../assets/images/about-9.png"),
          lightgray 50% / cover no-repeat;
        background-size: cover;
        margin-right: -62px;
      }
    }
  }

  .about-10 {
    width: 100%;
    height: 683px;
    position: relative;

    .image {
      width: 100%;
      height: 683px;
      flex-shrink: 0;
      position: absolute;
      background:
        url("../../assets/images/about-10.png"),
        lightgray 50% / cover no-repeat;
      background-size: cover;
    }

    .text {
      width: 256px;
      height: 240px;
      position: absolute;
      bottom: 62px;
      right: 62px;
      color: #fff;

      /* Body text */
      font-family: Radikal;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%; /* 22.4px */
    }
  }

  .about-11-12-13 {
    display: flex;
    width: 100%;
    height: 1560px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .layout {
      width: 100%;
      min-width: 1194px;
      max-width: 1366px;
      display: flex;
      gap: 100px;
      flex-direction: column;
      padding: 75px 62px;
    }

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background:
        url("../../assets/images/about-11-12-13-bg.png"),
        lightgray 50% / cover no-repeat;
      background-size: cover;
    }

    .about-11-12 {
      display: flex;
      width: 1304px;
      align-items: center;
      gap: 100px;
      position: relative;

      .left {
        display: flex;
        width: 490px;
        padding-bottom: 75px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        flex-shrink: 0;
        align-self: stretch;
        position: relative;

        .image-11 {
          width: 552px;
          height: 354.954px;
          margin-left: -62px;
          background:
            url("../../assets/images/about-11.png"),
            50% / cover no-repeat;
          background-size: cover;
        }

        .text {
          align-self: stretch;
          color: #fff;

          /* Body text */
          font-family: Radikal;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%; /* 22.4px */
        }
      }

      .image-12 {
        width: 714px;
        height: 806.611px;
        flex-shrink: 0;
        margin-right: -62px;
        background:
          url("../../assets/images/about-12.png"),
          lightgray 50% / cover no-repeat;
        background-size: cover;
      }
    }

    .about-13 {
      display: flex;
      align-items: center;
      gap: 100px;
      align-self: stretch;
      position: relative;

      .text {
        display: flex;
        width: 310px;
        flex-direction: column;
        align-items: flex-start;
        gap: 37px;

        .title {
          align-self: stretch;
          color: #c7a446;

          /* Brochure header */
          font-family: Radikal;
          font-size: 42px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          text-transform: uppercase;
        }

        .content {
          align-self: stretch;
          color: #fff;

          /* Body text */
          font-family: Radikal;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 140%; /* 22.4px */
        }
      }

      .image {
        width: 894px;
        height: 502.875px;
        background:
          url("../../assets/images/about-13.png"),
          lightgray 50% / cover no-repeat;
        background-size: cover;
        margin-right: -62px;
      }
    }
  }

  .about-14 {
    width: 100%;
    height: 854px;
    position: relative;
    padding: 62px;

    .image {
      width: 100%;
      height: 854px;
      position: absolute;
      top: 0;
      left: 0;
      background:
        url("../../assets/images/about-14.png"),
        lightgray 50% / cover no-repeat;
      background-size: cover;
    }

    .overlay {
      width: 100%;
      height: 854px;
      flex-shrink: 0;
      opacity: 0.125;
      background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
      mix-blend-mode: multiply;
      position: absolute;
    }

    .text {
      .title {
        position: absolute;
        left: 62px;
        width: 303.5px;
        flex-shrink: 0;
        color: #fff;

        /* Brochure header */
        font-family: Radikal;
        font-size: 42px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
      }

      .content {
        position: absolute;
        right: 62px;
        width: 695px;
        flex-shrink: 0;
        color: #fff;

        /* Body text */
        font-family: Radikal;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%; /* 22.4px */
      }
    }

    .logo {
      position: absolute;
      bottom: 62px;
      right: 62px;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      span {
        color: #fff;
        text-align: center;
        font-family: Radikal;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 9.9px;
      }
    }
  }

  .about-15 {
    position: relative;
    width: 100%;
    height: 859px;
    margin-bottom: 80px;

    .image {
      width: 100%;
      height: 859px;
      position: absolute;
      background:
        url("../../assets/images/about-15.png"),
        lightgray 50% / cover no-repeat;
      background-size: cover;
    }

    .overlay {
      width: 100%;
      height: 859px;
      position: absolute;
      opacity: 0.25;
      background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
      mix-blend-mode: multiply;
    }

    .text {
      .logo {
        position: absolute;
        left: 62px;
        bottom: 62px;
        width: 150px;
        height: 70px;
        flex-shrink: 0;
        background:
          url("../../assets/images/about-15-logo.png"),
          50% / cover no-repeat;
        background-size: cover;
      }

      .content {
        position: absolute;
        right: 62px;
        bottom: 62px;
        width: 722px;
        flex-shrink: 0;
        color: #fff;

        /* Body text */
        font-family: Radikal;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%; /* 22.4px */
      }
    }
  }
}

.wrap-top-nav {
  background: unset !important;
}

.static-page {
  overflow-y: auto;
}

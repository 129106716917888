.wrap-gallery-image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #5b5b5b;

  .slick-slider {
    max-height: 100vh;
    overflow: hidden;

    .gallery-item {
      width: 100vw;
      height: 100vh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;

      .gallery-hotspot {
        position: absolute;
        z-index: 100;
        cursor: pointer;
        width: 30px;
      }
    }
  }
  .slick-dots {
    bottom: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
      padding: 0;
      margin: 0 20px;
      max-width: 124px;
      overflow: auto;
      white-space: nowrap;
    }

    ul::-webkit-scrollbar {
      display: none;
    }

    li {
      margin: 0 7px;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background-color: transparent;
      border: 1.5px solid #C7A446;
      cursor: pointer;

      button {
        display: none;
      }

      &.dot-active {
        background-color: #C7A446;
      }
    }
  }

}

.btn-gallery {
  display: block;
  position: absolute;
  top: 50%;
  cursor: pointer;
}

.btn-gallery.prev-slide {
  left: 10vw;
  color: rgba(255, 255, 255, 0.6);
  img{
    transform: rotate(180deg);
    width: 21px;
  }
}

.btn-gallery.next-slide {
  right: 10vw;
  color: rgba(255, 255, 255, 0.6);
  img{
    width: 21px;
  }
}

.wrap-create-gallery-modal {
  .modal-content {
    border-radius: 0px;
  }

  .content {
    margin-bottom: 32px;

    .form-control {
      border-radius: 0px;
    }
  }

  .modal-form__title {
    font-size: 20px;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: bold !important;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #000000;
    margin-bottom: 14px;
  }

  button {
    border: 0px;
    padding: 0px;
    text-decoration: underline;
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
  .wrap-select-gallery-type {
    .css-1s2u09g-control {
      border-radius: 0;
    }
  }
}
.wrap-media-picker {
  height: 400px;
  overflow-y: scroll;
  .list-group {
    border-radius: 0;
    .list-group-item {
      border: 1px solid #c1c1c1;
    }
    .row {
      align-items: center;
    }
  }
}

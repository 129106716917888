.wrap-holding-page {
  background-color: #000000;
  .modal-form {
    color: #fff;
    font-size: 14px;
    width: 666px;
    background: #000000;
    padding: 36px 75px;
    box-shadow: -4px 5px 4px 0 rgba(0, 0, 0, 0.25);
  }
  .modal-form__body{
    margin-top: 50px;
    width: 450px;
  }
  .modal-form__body .form-group {
    margin-top: 10px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 39px;
    padding: 0;
  }
  .modal-form__body .form-control {
    border: 0;
    height: 46px;
    line-height: 2;
    background: #fff;
    font-size: 14px;
    color: #414345;
    border-radius: 39px;
    padding: 0 19px !important;
    text-align: left;
    &:focus {
      box-shadow: none;
    }
    ::placeholder{
      color: #C9C9C9;
    }
  }
  .button-submit{
    padding: 12px 19px;
    margin-top: 15px;
    width: 80px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 39px;
    order: 2;
    color: #000000;
  }
}
